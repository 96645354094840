import React, { useCallback, useContext, useEffect, useState } from "react";
import BookingAndPayment from "./BookingAndPayment";

import Modal from "../../utils/Modal"
import SocialProof from "./SocialProof";
import { Button } from "@mui/material";
// import InfoIcon from '@mui/icons-material/Info';
import ScheduleIcon from "@mui/icons-material/Schedule";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PaymentIcon from "@mui/icons-material/Payment";
import EditIcon from "@mui/icons-material/Edit";

import "./css/HumanInterviewerCoach.css";
import { logToBackendLogFile } from "../../externalLayerAccessor/BackEndRequests";
import StateAccessor from "../../StateAccessor";
import { doNothing } from "@/utils/HelperFunction";
import FeatureGrid from "./FeatureGrid";
import { ContactDetails } from "../Contact";
import PrepSessionHighlights from "./PrepSessionHighlights";
import FAQs, { FaqItem } from "../FAQs"



interface HumanInterviewPreparationProps {
  // Custom props as needed
}

interface MainMessageProps {
  // Custom props as needed
}

interface HowItWorksProps {
  // Custom props as needed
  disabled: boolean;
}

interface ModalPopUpButtonWithThumbnailPreviewProps {
  buttonText: React.ReactNode;
  modalContent: React.ReactNode;
  videoId?: string;
  isDisabled?: boolean;
}


/*
The ModalButton component is effectviely a button that opens a modal when clicked. 
It has videoId as an optional prop, which is used to display a thumbnail image for the video.

*/
const ModalPopUpButtonWithThumbnailPreview: React.FC<ModalPopUpButtonWithThumbnailPreviewProps> = ({ buttonText, modalContent, videoId, isDisabled = false }) => {

  if (isDisabled) {
    return null;
  }

  const [isOpen, setIsOpen] = useState(false);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const buttonStyle = videoId
    ? { '--thumbnail-url': `url(https://img.youtube.com/vi/${videoId}/hqdefault.jpg)` }
    : undefined;

  return (
    <>
      <button
        className={`book-button ${videoId ? 'button-with-thumbnail' : ''}`}
        onClick={handleModalOpen}
        style={buttonStyle as React.CSSProperties}
      >
        {videoId && <div className="thumbnail-background"></div>}
        <span>{buttonText}</span>
      </button>
      {isOpen && (
        <Modal content={modalContent} onClose={handleModalClose} />
      )}
    </>
  );
};



const MainMessage: React.FC<MainMessageProps> = (props) => {
  return (
    <div className="main-message-container landing-page-row">
      <h1>Don't Miss Out on Your Target Job for Preventable Reasons</h1>
      <h2>Avoid Surprises: Prove You Can Perform Under Interview Conditions</h2>
      <h3 className="highlight-text">
        Our Insights and Tailored Guidance Give You an Edge Over the Competition
      </h3>
      <div className="pain-points-container">
        <div className="pain-points-container-item">
          💡 "I suck at coming up with optimal solutions"
        </div>
        <div className="pain-points-container-item">
          🧠 "I keep forgetting what I've learned"
        </div>
        <div className="pain-points-container-item">
          📝 "I need an efficient & structured study plan"
        </div>
        <div className="pain-points-container-item">
          🤔 "I get brain freezes during interviews"
        </div>
        <div className="pain-points-container-item">
          ❓ "I can't solve unfamiliar problems"
        </div>
        <div className="pain-points-container-item">
          ⏰ "I can't solve problems within the time limit"
        </div>
        <div className="pain-points-container-item">
          🐜 "I always have bugs or miss edge cases"
        </div>
        <div className="pain-points-container-item">
          💬 "I struggle with expressing my thoughts as code"
        </div>
        <div className="pain-points-container-item">
          👥 "I need help from someone who's been there and done that"
        </div>
        <div className="pain-points-container-item">
          🏆 "Not getting the offers I want, I need an edge"
        </div>
      </div>

      <div className="main-message-button-container">
        <Button
          className="silver-button"
          variant="contained"
          color="primary"
          onClick={() => {
            doNothing();
          }}
          disabled={false} // for the appearance not to be greyed out
          style={{
            cursor: "default",
            textTransform: "none",
          }}
        >
          Join 1,000+ Happy Customers: who've rated us ⭐⭐⭐⭐⭐ (4.99 on
          average)
        </Button>

        {/* <Button
                    className='secondary-call-to-action-button'
                    variant="contained"
                    endIcon={<InfoIcon />}
                    onClick={() => { doNothing() }}
                    style = {{backgroundColor: 'grey'}}

                >
                    Pricing
                </Button> */}
      </div>
    </div>
  );
};

const HowItWorks: React.FC<HowItWorksProps> = (props) => {
    if (props.disabled) {
        return null;
    }
    return (
        <div className="how-it-works-container landing-page-row alternate-theme-container" id="booking-and-payment-guidance-section">
            <h1>How Scheduling Sessions Works</h1>
            
            <div className="session-text-container">
              <div className="session-text-left">
                <p>
                    <AssignmentIcon /> <strong>Step 1:</strong> Book a session
                    <ul>
                        <li><ScheduleIcon /> Each session is an <strong>hour long</strong>.</li>
                        <li> The booking process is separate from payment, so you can secure bookings for as many sessions as you want without payment</li>
                        <li> You can't book all sessions in one fell swoop i.e. you have to book each session individually.</li>
                        <li>
                            If you don't know how many sessions you want you can just book your first session, and add more later.
                        </li>
                        <li>
                            You will have the option to indicate the focus area of each session, e.g. coding, system design, behavioral, etc.
                        </li>
                        <li>
                            A booking confirmation email will be sent to you (check your spam folder if you don't see it).
                        </li>
                    </ul>
                    <PaymentIcon /> <strong>Step 2:</strong> Pay for your session(s) after booking.
                </p>
                <p><strong><em><EditIcon /> Note: You can always adjust your booked slots at no extra cost.</em></strong></p>
              </div>
              <div className="session-text-right">
              <div className="contact-mock-examples">
                <ModalPopUpButtonWithThumbnailPreview 
                  buttonText={<>View <br/> Sample <br/> Sessions</>}
                  modalContent={<PrepSessionHighlights/>}
                  videoId="Lq8BbgDM0uw"
                  isDisabled= {true}
                />
              </div>
              </div>
            </div>
        </div>
  );
};

interface ContactSectionProps {
  // Custom props as needed
}

const ContactSection: React.FC<ContactSectionProps> = (props) => {
  const faqs: FaqItem[] = [
    {
      question: "How do you guarantee a realistic simulation of mock interviews?",
      answerHtml: `
        <p>We achieve this through <strong>five</strong> approaches:</p>
        <ul>
            <li><strong>Recent Candidate Debriefs:</strong> We regularly engage with candidates we've worked with to gain detailed insights into their most recent interview experiences. For example, in mid-2024, we noticed many candidates reporting that they were being asked five Amazon Leadership Principles questions in a given round, as opposed to the usual two to three. By identifying this pattern early, we were able to coach our candidates to be prepared for this worst-case scenario, helping them avoid surprises and perform at their best.</li>
            <br/>
            <li><strong>Comprehensive Industry Monitoring:</strong> We constantly track interview experiences shared across platforms like Glassdoor, Blind, LeetCode, Reddit, and others. This allows us to spot emerging patterns or changes in real-time, saving you the hassle of monitoring these sources yourself. With this comprehensive insight, we adapt our guidance and preparation strategies to ensure you're ready for any shifts in the interview landscape.</li>
            <br/>
            <li><strong>Interviewer Profile Variants:</strong> In some companies, the interview process is not standardized enough to eliminate the variance a candidate might experience based on who their interviewer is. We allow you to experience different interviewer personalities and what they bring to the interview—whether it's someone chatty who might distract you, someone who puts you under pressure, or someone with a rapid-fire questioning style. You can choose your interviewer’s personality or let it be random, providing you with realistic preparation for any interviewer type you might encounter.</li>
            <br/>
            <li><strong>Experienced Interviewers:</strong> Our mock interviewers have not only gone through interviews at a wide range of top tech companies (FAANG and beyond), but have also been interviewers themselves. They know exactly what different companies are looking for and can provide detailed, up-to-date feedback based on current hiring standards. Their insights help you avoid the common mistakes that can trip up candidates.</li>
            <li><strong>Accomodating your Insights:</strong> Often we have customers who've been given an idea of what to expect in their interviewer e.g. they might be told the interview will focus on concurrency in Java. During booking, you have the option to provide this information, and we always tailor the interview to your custom needs.</li>

        </ul>
      `,
    },
    {
      question: "What's the difference between a diagnostic, coached & regular mock interview?",
      answerHtml: `
        <p>We offer several types of mock interviews, each tailored to different needs:</p>
        <ul>
            <li><strong>Mock Interview:</strong> This is a simulation of a real interview with your target company. If you don’t have a specific company in mind, we provide a general mock interview to replicate a typical interview scenario, designed to give you a realistic feel of what to expect.</li>
            <br/>
            <li><strong>Coached Mock Interview:</strong> This is a realistic mock interview with a twist—the interviewer also acts as a coach. While simulating the interview, the coach will step in to provide real-time feedback whenever you make mistakes. This allows you to immediately form better habits, helping you correct any suboptimal techniques or approaches on the spot.</li>
            <br/>
            <li><strong>Diagnostic Mock Interview:</strong> This interview is designed to identify your knowledge gaps and weaknesses in interview skills. The questions are specifically tailored to expose areas where you may need improvement, allowing you to focus your preparation on those areas.</li>
            <br/>
            <li><strong>Coaching Sessions:</strong> We also offer coaching sessions, which are not mock interviews. These sessions are more flexible and can be customized to your needs. You can ask questions about anything—whether it's system design, dynamic programming, structuring your learning, or optimizing your preparation for a specific company. Coaching sessions are tailored to your specific requirements.</li>
        </ul>
      `,
    },
    {
      question: "What if I need to reschedule a session that I've booked?",
      answerHtml: `
        <p>You can reschedule your session with ease, and there's no charge for doing so. Our booking system is designed so that booking an interview slot is decoupled from the payment process. This means you can secure your slot and modify the dates if necessary. We only ask that you give us some notice—ideally more than one hour before the session. However, we are quite flexible when it comes to rescheduling.</p>
      `,
    },
  ];

  return (
    <div className="contact-section-container landing-page-row">
      <h1>Still have questions?</h1>

      <div className="contact-section-child-container">
        <div className="contact-section-image-container">
          <figure>
            <img
              src="https://coditioning3.files.wordpress.com/2024/01/brain-freeze.png?w=600"
              alt="ConfusedImage"
            />
          </figure>
        </div>
        <div className="contact-section-right-container">
          <div className="faq-container">
              <FAQs faqs={faqs} />
          </div>
          <div className="contact-details">
            {<ContactDetails />}
            <div className="contact-mock-examples">
              {/* <h3>Want a taste of our sessions?</h3> */}
              <ModalPopUpButtonWithThumbnailPreview 
                buttonText={<> View Sample <br/> Mock & Coaching Sessions</>}
                modalContent={
                    <PrepSessionHighlights />}
                videoId="Lq8BbgDM0uw"
                isDisabled= {true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const scrollToFocusSection = () => {
  // Get the URL parameters
  const params = new URLSearchParams(window.location.search);
  const scrollTo = params.get("scrollTo");

  // If the scrollTo parameter exists, scroll to the specified section
  if (scrollTo) {
    const element = document.getElementById(scrollTo);
    if (element) {
      element.scrollIntoView();
    }
  }
};

const HumanInterviewerCoach: React.FC<HumanInterviewPreparationProps> = (
  props
) => {
  const { userId } = useContext(StateAccessor);

  useEffect(() => {
    scrollToFocusSection();
    logToBackendLogFile(
      `Viewed HumanInterviewerCoach Component`,
      "info",
      userId
    );
  }, [userId]);

  return (
    <div className="humanInterviewCoach">
      <MainMessage />
      <SocialProof disabled={false} />
      <FeatureGrid />
      <HowItWorks disabled={false} />
      <BookingAndPayment />
      <ContactSection />
    </div>
  );
};

export default HumanInterviewerCoach;
