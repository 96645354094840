import React, { useContext, useCallback, useEffect, useState } from 'react';
import { CourseContext } from '../utils/CoursesContext';
import '../css/CoursesMenu.css';
import Progress from '../utils/Progress';
import { useNavigate } from 'react-router-dom';
import { getUserCourseProgress } from '../externalLayerAccessor/BackEndRequests';
import { UserProgress, ProgressStatus } from '../utils/Constants';
import Ribbon from '../utils/Ribbon';
import StateAccessor from '../StateAccessor';
interface CoursesMenuProps {
  disabled?: boolean;
  progress: Progress;
}

/**
 * Component to display the courses menu
 * @param {CoursesMenuProps} props - props passed to the component
 */
const CoursesMenu: React.FC<CoursesMenuProps> = ({ progress, disabled = false }) => {
  const { courses } = useContext(CourseContext);
  const navigate = useNavigate();
  const { userId } = useContext(StateAccessor);

  // State to store course progress
  const [courseProgressMap, setCourseProgressMap] = useState<Map<number, UserProgress[]>>(new Map());

  useEffect(() => {
    async function fetchAllCourseProgress() {
      if (courses) {
        const progressMap = new Map<number, UserProgress[]>();
        await Promise.all(
          courses.map(async (course) => {
            const courseProgress = await getUserCourseProgress(userId, course.course_id);
            if (courseProgress !== null) {
              progressMap.set(course.course_id, courseProgress);
            }
          })
        );
        setCourseProgressMap(progressMap);
      }
    }

    fetchAllCourseProgress();
  }, [userId, courses]);

  // Handles navigation to a course on click
  const handleClick = useCallback((courseId: number) => {
    navigate(`./courses/${courseId}`);
  }, [navigate]);

  // If there are no courses
  if (!courses) {
    return null;
  }

  // If component is disabled, return null
  if (disabled) {
    return null;
  }

  return (
    <div className='courses-menu'>
      <div className="menu-cards-container">
        {courses.map((course) => (
          <div key={course.course_id} className="course-card" onClick={() => handleClick(course.course_id)}>
            <div className="course-card-bg" style={{ backgroundImage: `url(${course.thubmnail_url})` }}></div>
            <h5 className='course-card-title'>{course.title}</h5>
            <div className="course-progress">
              {/* Display progress data here */}
              {courseProgressMap.get(course.course_id)?.map((progress, index) => (
                <div key={index}>
                  <Ribbon
                    parameter={progress.status}
                    data={{
                      [ProgressStatus.InProgress]: { color: 'yellow', text: 'In Progress' },
                      [ProgressStatus.Completed]: { color: 'green', text: 'Completed' },
                      [ProgressStatus.NotStarted]: { color: 'red', text: 'Not Started' },
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoursesMenu;
