import React, { useContext, useState } from "react";
import StateAccessor from "../StateAccessor";
import CodeEditorBox from "./CodeEditorBox";
import { CodeBlockByLanguage, Language } from "../utils/Constants";
import { doNothingAsync } from "../utils/HelperFunctions";
import '../css/CodingSandbox.css';


/////////////////////////////////
// Constants
/////////////////////////////////
const CODE_STUB_BY_LANGUAGE: Readonly<CodeBlockByLanguage> = {
    Python: "# Write your code here",
    Java: "// Write your code here",
    'C++': "// Write your code here",
    C: "// Write your code here",
    'C#': "// Write your code here",
    JavaScript: "// Write your code here",
    Go: "// Write your code here",
    Swift: "// Write your code here",
    TypeScript: "// Write your code here",
    Kotlin: "// Write your code here",
    Ruby: "# Write your code here",
};


const MOCK_INTERVIEW_UNIT_ID: number = 1;
const MOCK_INTERVIEW_TIMER_KEY: number = 0;
const MOCK_INTERVIEW_TIME_LIMIT_IN_MINUTES: number = 45;

const CodingSandbox: React.FC = () => {
    const { userId } = useContext(StateAccessor);
    const [codeEditorValue, setCodeEditorValue] = useState<string>("");
    const [preferredProgrammingLanguage, setPreferredProgrammingLanguage] = useState<Language>(Language.Go);
    const [isTimerRunning, setIsTimerRunning] = useState<boolean>(true);


    const handleTimerComplete = () => {
        //raise alert when timer is complete
        alert("Time is up!");
    }

    return (
        <div className='coding-sandbox-container'>
            <CodeEditorBox
                userId={userId}
                codeEditorValue={codeEditorValue}
                setCodeEditorValue={setCodeEditorValue}
                codeBlockByLanguage={CODE_STUB_BY_LANGUAGE}
                timerComplete={handleTimerComplete}
                unit_id={MOCK_INTERVIEW_UNIT_ID}
                codeSubmissionHandler={doNothingAsync}
                codeRunHandler={doNothingAsync}
                rightAnswer={null}
                timerKey={MOCK_INTERVIEW_TIMER_KEY}
                codeEditorSettings={
                    {
                        isRunningDisabled: true,
                        disabled: false,
                        isSubmissionDisabled: true, // disabled for mock interviews
                        programmingLanguage: preferredProgrammingLanguage,
                        setProgrammingLanguage: setPreferredProgrammingLanguage,
                        isAssessment: false,
                        timeLimitInMinutes: MOCK_INTERVIEW_TIME_LIMIT_IN_MINUTES,
                        isTimerDisabled: false,
                        isTimerRunning: isTimerRunning,
                        setIsTimerRunning: setIsTimerRunning,
                        programmingLanguageWhiteList: new Set(Object.values(Language)),
                        codeEditorHeightInPx: 700, /*until we have a better way to handle this issue, ensure this value is <= .coding-sandbox-container height*/
                        shouldPersistCode: true,
                    }
                }
            />
        </div>
    )
}

export default CodingSandbox; 