import React, { useContext } from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PaymentIcon from '@mui/icons-material/Payment';
import { Button } from '@mui/material';
import './css/BookingAndPayment.css';
import { openUrlInNewTab } from '../../utils/HelperFunctions';
import StateAccessor from '../../StateAccessor';
import { MONEY_BACK_GUARANTEE_STATEMENT } from '../Terms';
import { NOT_SIGNED_IN_USER_USER_ID } from '@/utils/Constants';


export const DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL = 'https://calendly.com/coditioning/company-specific-dedicated-interview-prep';


export class Promo {
    static readonly CODE = 'GETTRULYREADYFORLESS';
    static readonly DISCOUNT_AS_INTEGER = 33; // 33% discount as an integer
  }

interface BookingOptionsProps {
    userId: number;
}

interface BookSessionButtonProps {
    userId?: number;
}

const BookSessionButton: React.FC<BookSessionButtonProps> = ({userId=NOT_SIGNED_IN_USER_USER_ID }) => {
    const openUrlInNewTab = (url: string, userId: number) => {
      const newUrl = `${url}?userId=${userId}`;
      window.open(newUrl, '_blank');
    };
  
    return (
      <Button
        className='book-session-button'
        variant="contained"
        color="primary"
        onClick={() => openUrlInNewTab(DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL, userId)}
        style={{
          textTransform: 'none',
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          fontSize: '1.3rem',
          width: 'auto',
        }}
        title="Book a dedicated interview prep session"
      >
        <ScheduleIcon style={{ marginRight: '0.5rem' }} />
        Book Session (No Payment Required)
      </Button>
    );
  };

const BookingOptions: React.FC<BookingOptionsProps> = ({ userId }) => {
    return (
        <>

            <div className="booking-options-container" id="booking-section">
                <h1>1) Book one or more sessions</h1>

                <BookSessionButton userId={userId} />

            </div>


        </>
    );
};



interface PaymentOptionsProps {
    userId: number;
}


const PaymentOptions: React.FC<PaymentOptionsProps> = ({ userId }) => {
    const BUNDLED_SESSIONS_PAYMENT_URL = "https://buy.stripe.com/4gw8xg2J12hBeCkfZe";
    const SINGLE_SESSION_PAYMENT_URL = "https://buy.stripe.com/9AQ9Bk1EX09t8dWdR7";
    const CODITIONING_360_PAYMENT_URL = "https://buy.stripe.com/6oE8xg6Zh09t9i0eVc";

    const standardPrepSessionFeatures = (
        <>
          <div className="service-feature">✅ 💯% money back guarantee</div>
          <div className="service-feature">✅ Pair with a FAANG+ senior engineer (10+ years)</div>
          <div className="service-feature">✅ Hire or No-Hire Decision (Know where you stand)</div>
          <div className="service-feature">✅ Tailored to your target companies</div>
          <div className="service-feature">✅ Detailed actionable feedback to level you up quickly</div>
          <div className="service-feature">✅ Real-time feedback (coaching sessions)</div>
          <div className="service-feature">✅ Access to private recording of the session (if you opt in)</div>
        </>
      );

      const renderPriceTag = (officialPrice: number) => {

        const promotionalPrice: number = parseFloat((officialPrice * (1 - Promo.DISCOUNT_AS_INTEGER / 100)).toFixed(2));
        return (
            <div className="price-tag">
            <del>${officialPrice}</del> ${promotionalPrice} per session (1hr)
            <div className="price-tag-subtitle"><strong>Save {Promo.DISCOUNT_AS_INTEGER}%</strong> with code <strong>{Promo.CODE}</strong></div>
          </div>
        );
      };

    return (
        <>
            <h1 id='payment-section'> 2) Pay </h1>
            <div className="payment-options-grid">
                
                <div className="booking-type-container">
                    <div className='feature-header'>
                        <h1>Bundled Sessions</h1>
                        <span className='recommendedTag'> Cheapest</span>
                    </div>

                    
                    {renderPriceTag(150)}
                    <Button
                        className='payment-button'
                        variant="contained"
                        color="secondary"
                        onClick={() => openUrlInNewTab(BUNDLED_SESSIONS_PAYMENT_URL, userId)}
                        style={{
                            marginTop: '0.5rem',
                            backgroundImage: 'linear-gradient(189deg, #ffff99, #fdd835)',
                            color: 'black',
                            textTransform: 'none'
                        }}
                    >
                        <PaymentIcon style={{ marginRight: '0.5rem' }} />
                        Purchase Bundled Sessions
                    </Button>
                    

                    {/* Features */}
                    {standardPrepSessionFeatures}
                    <div className="service-feature">✅ Save when you book multiple sessions</div>
                    <div className="service-feature">✅ Mix & match different session domains (system design, coding, behavioural etc)</div>

                </div>

                <div className="booking-type-container">
                    <div className='feature-header'>
                        <h1>Single Session</h1>
                        <span className='recommendedTag'> Best for sampling</span>
                    </div>

                    {renderPriceTag(170)}

                    <Button
                        className='payment-button'
                        variant="contained"
                        color="secondary"
                        onClick={() => openUrlInNewTab(SINGLE_SESSION_PAYMENT_URL, userId)}
                        style={{
                            marginTop: '0.5rem',
                            backgroundImage: 'linear-gradient(189deg, #ffff99, #fdd835)',
                            color: 'black',
                            textTransform: 'none'
                        }}
                    >
                        <PaymentIcon style={{ marginRight: '0.5rem' }} />
                        Purchase Single Session
                    </Button>

                    {/* Features */}
                    {standardPrepSessionFeatures}

                    
                </div>

                <div className="booking-type-container">
                    <div className='feature-header'>
                        <h1>Coditioning 360°</h1>
                        <span className='recommendedTag'>Comprehensive</span>
                    </div>
                    
                    {renderPriceTag(260)}
                    <Button
                        className='payment-button'
                        variant="contained"
                        color="secondary"
                        onClick={() => openUrlInNewTab(CODITIONING_360_PAYMENT_URL, userId)}
                        style={{
                            marginTop: '0.5rem',
                            backgroundImage: 'linear-gradient(189deg, #ffff99, #fdd835)',
                            color: 'black',
                            textTransform: 'none'
                        }}
                    >
                        <PaymentIcon style={{ marginRight: '0.5rem' }} />
                        Purchase Coditioning 360°
                    </Button>

                    <div className="service-feature">✅ 💯% money-back guarantee*</div>
                    <div className="service-feature">✅ Pair with a FAANG+ senior engineer (10+ years of experience)</div>
                    <div className="service-feature">✅ End-to-end guidance</div>
                    <div className="service-feature">✅ Access to the Coditioning Lounge (Private Slack Server)</div>
                    <div className="service-feature">✅ Full support outside coaching/mock sessions</div>
                    <div className="service-feature">✅ Tailored interview prep roadmap</div>
                    <div className="service-feature">✅ 5+ hour-long sessions</div>
                    <div className="service-feature">✅ Target company-specific tailoring</div>
                    <div className="service-feature">✅ Rigorous Data Structures & Algorithms Strengths & Weaknesses Diagnostic</div>
                    <div className="service-feature">✅ Behavioral Response Crafting</div>
                    <div className="service-feature">✅ At least 1 Strategy Session</div>
                    <div className="service-feature">✅ At least 3 Mock Interviews</div>
                    <div className="service-feature">✅ Team-matching & negotiation guidance</div>
                    <div className="service-feature">✅ Full access to Coditioning Premium</div>
                    <div className="service-feature">✅ CV Review</div>

                    
                    
                </div>

            </div>

            <div className="money-back-guarantee">
                <p>
                    <a href="/terms/#money-back-guarantee">
                        <strong>*{MONEY_BACK_GUARANTEE_STATEMENT}</strong>
                    </a>
                </p>
            </div>
        </>
    );
};


const BookingAndPayment: React.FC = () => {
    const { userId } = useContext(StateAccessor);

    return (
        <div className="booking-and-payment-container landing-page-row">
            <BookingOptions userId={userId} />
            <hr />
            <PaymentOptions userId={userId} />


        </div>
    );
};
export { BookingAndPayment as default, BookSessionButton };
