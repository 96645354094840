import React from 'react';
import TargetTechEmployersIcon from '../TargetTechEmployersIcon';
import { StyleType, Testimonial, TestimonialType, MultiModalTestimonialBoard } from '@/utils/MultiModalTestimonials';
import { TechCompanyLogoUrl } from '@/utils/Constants';

const sampleTestimonials: Testimonial[] = [
    {
        id: '1',
        userDisplayName: 'Sonjay',
        type: TestimonialType.VIDEO,
        videoEmbedUrl: "https://www.youtube.com/embed/wlUkzFxoETg?si=7Np3IpYE51N3D8z0",
        altText: "Podcast episode of Sonjay's Google Interview Experience",
        caption: "My Google mocks with Coditioning were possibly the best investment I've made in my life. I  received detailed feedback on bad signals I was giving off without  knowing, and the sessions also helped me optimize for Google's  interviewing style. I cleared the coding rounds with positive feedback  in all rounds, and received an L3 SWE offer.",
        companyLogoUrl: TechCompanyLogoUrl.GOOGLE,
        companyName: 'Google',
    },
    {
        id: '2',
        userDisplayName: 'Bosiang.H',
        type: TestimonialType.VIDEO,
        videoEmbedUrl: "https://www.youtube.com/embed/X9TNNWdJBJs?si=dBpo3m_UOTy0IGr8",
        altText: "Podcast episode of Bosiang's Amazon SDE II Interview Experience",
        caption: "In this episode of the Coditioning podcast, we catch up with Bosiang, who recently went through the Amazon SDE II interview loop. Bosiang had 7 sessions with Coditioning (mock interviews & coaching) and ended up receiving an offer in the fall of 2024. Listen to this episode, where he shares his experience.",
        companyLogoUrl: TechCompanyLogoUrl.AMAZON,
        companyName: 'Amazon',
    },
    {
        id: '4',
        userDisplayName: 'Diana',
        type: TestimonialType.VIDEO,
        videoEmbedUrl: "https://www.youtube.com/embed/Z0XGUQk3NO0?si=eo-yCxpNQ4gY8sM9",
        altText: "Video Testimonial of Interviewing Service from a Meta Employee",
        caption: "In this episode of the Coditioning podcast, we meet Diana, who recently went through the META interview loop for a New Graduate Production Engineer role. Diana had 7 sessions with Coditioning (mock interviews & coaching) and ended up receiving an offer in the fall of 2024. Listen to this episode, where she shares her experience.",
        companyLogoUrl: TechCompanyLogoUrl.META,
        companyName: 'Meta',
    },
    {
        id: '3',
        userDisplayName: 'Anonymous Discord Workshop Attendee',
        type: TestimonialType.IMAGE,
        imageUrl: "https://coditioning3.wordpress.com/wp-content/uploads/2024/09/discord_amazon_testimonial.png.png?w=800",
        altText: "Discord Member Testimony for Human Interviewer Service",
        companyLogoUrl: TechCompanyLogoUrl.AMAZON,
        companyName: 'Amazon',
    },
    {
        id: '5',
        userDisplayName: 'H.Yun',
        type: TestimonialType.TEXT,
        text: "I recently landed a job offer working on AI infrastructure. I just want to say thank you to all your resources and your mock feedback. I genuinely appreciate your time & effort!",
        companyName: 'AI Startup',
    },
    {
        id: '6',
        userDisplayName: 'R.Wama',
        type: TestimonialType.TEXT,
        text: 'Got the Amazon offer. After bombing a bunch of interviews, it became clear that just grinding LeetCode wasn’t cutting it. I must have solved around 500 problems but I still sucked at interviews, and never got feedback from recruiters. The detailed feedback from Coditioning mocks were invaluable and really helped me improve. Just glad to be done with interviews. Thanks',
        companyName: 'Amazon',
        companyLogoUrl: TechCompanyLogoUrl.AMAZON,
    },
    {
        id: '7',
        userDisplayName: 'Sumanth',
        type: TestimonialType.TEXT,
        text: `I was referred by a friend and came at short notice to ask for a custom Python interview. I had to practice debugging actual Python code and making it production-ready. This isn't something
        I got much practice with when using LeetCode, but the service was really good. They uncovered so many gaps in my knowledge. I thought I understood generators and decorators, but boy was I wrong. Even some gaps in my knowledge of the basics were brought to light. Glad I did this mock.`
    },
];

interface SocialProofProps {
    // Custom props as needed
    disabled: boolean;
}

const SocialProof: React.FC<SocialProofProps> = (props) => {
    if (props.disabled) {
        return null;
    }
    return (
        <>
            <div className="social-proof-container landing-page-row">
                <h1 className='highlight-text'> Maximise your chances of securing offers from top employers:</h1>
                <TargetTechEmployersIcon
                    width="40%"
                    minWidth='300px'

                />

            </div>
            <div className="social-proof-container landing-page-row ">
                <h1 className='highlight-text'>Hone your interviewing skills through realistic mocks & coaching:</h1>
                {<MultiModalTestimonialBoard 
                    testimonials={sampleTestimonials}
                    itemsPerPage={6}
                    styleType={StyleType.STICKY_NOTE}
                />}
            </div>
        </>
    );
}

export default SocialProof;