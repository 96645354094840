import React, { useState, useContext } from 'react';
import { FaClock, FaQuestionCircle, FaBrain } from 'react-icons/fa';
import '../css/Courses.css';
import '../css/Assessments.css';
import Modal from '../utils/Modal';
import ChallengeTabbedView from './ChallengeTabbedView';
import CodeEditorBox from './CodeEditorBox';
// import LearningPageLinks from './LearningPageLinks';
import { Assessment, AssessmentAttemptReport, DEFAULT_CONTENT_CONTAINER_MAX_HEIGHT_IN_PX, Language, NOT_SIGNED_IN_USER_USER_ID, Unit } from '../utils/Constants';
import { useParams } from 'react-router-dom';
import { ChallengeType, TestCaseExecutionResponse, TestCaseExecutionRequest, AssessmentType, ChallengeDescriptionInterface } from '../utils/Constants';
import MultipleChoiceBox from './MultipleChoiceBox';
import { AssessmentContext } from '../utils/AssessmentContext';
import { executeCode, logToBackendLogFile } from '../externalLayerAccessor/BackEndRequests';
import Button from '@mui/material/Button';
import ChallengeInteractiveMenu from '../utils/InteractiveMenu';
import { convertChallengeTestCaseToCodeExecutionTestCase, convertLanguageToCodeExecutionLanguage, doNothing, doNothingAsync } from '../utils/HelperFunctions';
import AssessmentFeedback from './AssessmentFeedback';
import StateAccessor from '../StateAccessor';
import Modal2 from '@/utils/Modal2';
import Sidebar from '../utils/SideBar';

interface AssessmentInitialisationMenuProps {
  onClose: () => void;
  preferredProgrammingLanguage: Language;
  setPreferredProgrammingLanguage: (language: Language) => void;
  setIsTimerRunning: (isTimerRunning: boolean) => void;
  totalAllottedTimeInMinutes: number;
}

const AssessmentInitialisationMenu: React.FC<AssessmentInitialisationMenuProps> = ({ onClose, preferredProgrammingLanguage, setPreferredProgrammingLanguage, setIsTimerRunning, totalAllottedTimeInMinutes }) => {

  const handleStartAssessment = () => {
    onClose();
    setIsTimerRunning(true);
    logToBackendLogFile("An Assessment has started", "info");
  };
  return (
    <div className='initial-assessment-box'>
      {/* TODO: Uncomment the following section when the custom learning path feature is available */}
      {/*
      <div className="info-box">
        <p>
          <strong>Coming Soon:</strong> After completing this assessment, you'll receive a <strong>custom learning path</strong> tailored just for you. This path is optimized to maximize your learning speed and retention by leveraging spaced repetition and insights from neuroscience. With this path, you'll achieve the best results with the least amount of effort.
        </p>
      </div>
      */}

      <div className="assessment-init-menu-grid">
        <div className='assessment-init-menu-row assessment-guidance-container'>
          <h2 className='diagnostic-assessment-header'> Diagnostic Assessment Guidance</h2>
          <div className="assessment-guidance">
            <p className="intro">This assessment will help identify your strengths and areas for improvement. It will test your understanding of important concepts via these question types:</p>
            <ul>
              <li><FaClock style={{ display: 'inline-block', verticalAlign: 'middle' }} /><strong> Timed Coding Questions</strong></li>
              <li>
                <FaQuestionCircle style={{ display: 'inline-block', verticalAlign: 'middle' }} /> <strong>Truth or Despair Questions:</strong> Multiple choice questions, but options are hidden until you click either:
                <ul>
                  <li><em>&nbsp;&nbsp;&nbsp;&nbsp;- <strong>"I don't know"</strong> to skip: There's no penalty for skipping.</em></li>
                  <li><em>&nbsp;&nbsp;&nbsp;&nbsp;- <strong>Attempt</strong> to answer: There's a penalty for incorrect attempts</em></li>
                </ul>
              </li>
              <li><FaBrain style={{ display: 'inline-block', verticalAlign: 'middle' }}/> Questions vary in difficulty, and this will be indicated for each problem.</li>
            </ul>
          </div>
        </div>

        {/* add message indicating the maximum time allotted for the assessment */}

        <div className="assessment-init-menu-row">
          <p><FaClock style={{ display: 'inline-block', verticalAlign: 'middle' }}/> This assessment is timed and will take between <strong>1 to {totalAllottedTimeInMinutes} minutes</strong>, depending on your knowledge level.</p>
        </div>

        <div className="assessment-init-menu-row">
          <Button variant="contained" color="primary" onClick={handleStartAssessment} style={{ fontSize: '24px' }}>
            Start Assessment
          </Button>
        </div>
      </div>
    </div>
  );
};

interface TimedOutModalProps {
  handleUnitAdvance: () => void;
  onClose: () => void;
}

const TimedOutModal: React.FC<TimedOutModalProps> = ({ handleUnitAdvance, onClose }) => {
  const handleAdvanceClick = () => {
    onClose();
    handleUnitAdvance();
  };

  return (
    <Modal
      content={
        <div className='failure-modal'>
          <p>You've timedout on this question.</p>
          <div className='failure-buttons-container'>
            {<Button variant="contained" color="primary" onClick={handleAdvanceClick}>
              Continue
            </Button>}
          </div>
        </div>
      }
      closeDisabled={true}
      onClose={onClose}
    />
  );
};



interface AssessmentsProps {
  disabled?: boolean;
}
/**
 * Main assessment component
 * @param {{disabled: boolean}} AssessmentsProps - Props for the Assessments component.
 */
const Assessments: React.FC<AssessmentsProps> = ({ disabled = false }) => {
  const [showAssessmentInititialisationModal, setShowAssessmentInitialisationModal] = useState<boolean>(true);
  //const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [showTimedOutModal, setShowTimedOutModal] = useState<boolean>(false);
  const [showMCQOptions, setShowMCQOptions] = useState<boolean>(false);
  const [firstChoiceMade, setFirstChoiceMade] = useState<boolean>(false);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [currentUnitIndex, setCurrentUnitIndex] = useState<number>(0);
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);
  const [preferredProgrammingLanguage, setPreferredProgrammingLanguage] = useState<Language>(Language.Python);
  const [assessmentAttemptReport, setAssessmentAttemptReport] = useState<AssessmentAttemptReport>({
    assessment: null,
    unitAttemptReports: [],
  });

  const params = useParams();
  const { assessments } = useContext(AssessmentContext);
  const { userId } = useContext(StateAccessor);


  if (!assessments || !params.assessment_id) {
    return null;
  }
  const assessmentIndexFromURL = parseInt(params.assessment_id);

  const currentAssessment: Assessment = assessments[assessmentIndexFromURL];
  if (!currentAssessment) {
    console.error('Incorrect URL parameters');
    return null;
  } else {
    logToBackendLogFile(
      `Loaded assessement ${currentAssessment.title}`,
      "info",
      userId);
  }
  const totalAllottedTimeInMinutes: number = currentAssessment.estimated_completion_time_in_minutes;
  const units = currentAssessment.units;

  const currentUnit = units[currentUnitIndex];
  const currentChallenge = currentUnit.challenge;


  const challengeDescriptionValues: ChallengeDescriptionInterface = { //NITO: check default values
    unit: currentUnit,
    challengeTitle: currentChallenge.title,
    challengeType: currentChallenge.challenge_type,
    assessmentType: currentChallenge.assessment_type,
    problemStatement: currentChallenge.problem_statement,
    penalty: currentChallenge.penalty || 0,
    hints: currentChallenge.hints || [],
    difficulty: currentChallenge.difficulty,
    keyAssociations: currentChallenge.key_associations || [],
    totalNumChallengesInScope: currentAssessment.number_of_questions_to_answer
  };

  const handleUpdateAssessmentAttemptReport = (currentUnit: Unit, isCorrectAnswer: boolean | null) => {
    setAssessmentAttemptReport(prevAssessmentAttemptReport => {
      const updatedUnitAttemptReports = prevAssessmentAttemptReport.unitAttemptReports;
      updatedUnitAttemptReports.push({
        unit: currentUnit,
        answeredCorrectly: isCorrectAnswer
      });
      return {
        assessment: (prevAssessmentAttemptReport.assessment === null) ? currentAssessment : prevAssessmentAttemptReport.assessment,
        unitAttemptReports: updatedUnitAttemptReports
      };
    });
  }

  const handleToggleSidebar = (state: boolean) => {
    // setShowSidebar(state);
  };

  const handleShowMCQOptions = (showMCQOtions: boolean) => { //NITO-TODO: DELETE THIS
    if (!firstChoiceMade) { //NITO: what's significance of firstChoiceMade?
      setFirstChoiceMade(true);
      setShowMCQOptions(showMCQOtions)
    }
  };

  const handleTimerComplete = () => {
    setShowTimedOutModal(true); //NITO: is this always valid, what about race conditions?
  };

  const handleUnitAdvance = () => { // NOTE : could be adaptive
    if (currentUnitIndex < units.length - 1) {
      handleResetState();
      setCurrentUnitIndex(currentUnitIndex + 1);
      // scroll to top of page
      window.scrollTo(0, 0);
    } else if (currentUnitIndex === units.length - 1) {
      handleAssessmentEnd();
    }
  };

  const handleUserDeclaresNotKnowingMCQAnswer = () => {
    handleMCQSubmission(null);
    handleUnitAdvance();
  }

  const handleSkipQuestion = (assessmentType: AssessmentType) => {
    switch (assessmentType) {
      case AssessmentType.TimedCoding:
        handleUpdateAssessmentAttemptReport(currentUnit, null);
        break;
      case AssessmentType.TruthOrDespair:
        handleMCQSubmission(null);
        break;
      default:
        break;
    }
    handleUnitAdvance();
  };

  const handleAssessmentEnd = () => {
    handleResetState();
    setShowFeedback(true);
    logReportToBackend();

  };

  const logReportToBackend = () => {

    const messages = assessmentAttemptReport.unitAttemptReports.map((report) => {
      const title = report.unit.title;
      const answeredCorrectly = report.answeredCorrectly === null ? "not attempted" : report.answeredCorrectly ? "answered correctly" : "answered incorrectly";
      return `${title}: ${answeredCorrectly}`;
    });

    const reportMessage = messages.join("\n");
    logToBackendLogFile(
      `Assessment ${currentAssessment.title} completed.\n Report: \n${reportMessage}`,
      "info",
      userId
    )

  }

  const handleMCQSubmission = (answer: string | null) => {

    if (answer === null) {
      handleUpdateAssessmentAttemptReport(currentUnit, null);
    } else {
      const isCorrectAnswer = currentChallenge.acceptable_answers.includes(answer);
      handleUpdateAssessmentAttemptReport(currentUnit, isCorrectAnswer);
    }
    handleUnitAdvance();
  };

  const handleCheckTestCases = async (code: string | null, language: Language) => {

    if (code === null || code === "") {
      return; // do nothing
    }

    if (!currentChallenge.test_cases) { //TODO: remove this once test cases added as required field to Unit class, this is just temporaryy, as we haven't integraed test cases yet
      handleUpdateAssessmentAttemptReport(currentUnit, null);
      return;
    }

    const testCodeParams: TestCaseExecutionRequest = {
      user_id: NOT_SIGNED_IN_USER_USER_ID, // TODO: change this to the actual user id if you wnt to enable code execution in assessments,
      source_code: code,
      language: convertLanguageToCodeExecutionLanguage(language),
      test_cases: convertChallengeTestCaseToCodeExecutionTestCase(currentChallenge.test_cases)
    };

    const codeExecutionResult: TestCaseExecutionResponse | null = await executeCode(testCodeParams);
    if (codeExecutionResult === null) { //TODO: handle this better, for sure retry, but also maybe show error message to user
      console.error("Error occurred during code execution");
      handleUpdateAssessmentAttemptReport(currentUnit, null);
      return;
    }

    const isCorrectAnswer: boolean = codeExecutionResult.results.allTestCasesPassed;
    handleUpdateAssessmentAttemptReport(currentUnit, isCorrectAnswer);

  }

  const handleResetState = () => { //NITO: there needs to be a cleaner way to do this, so that all state is reset, and we don't have to do it manually i.e. dev should not have to remember to reset state if a new state is added
    setFirstChoiceMade(false);
    setShowMCQOptions(false);
    setShowTimedOutModal(false);
  }


  /*Render logic*/

  if (disabled) {
    return null;
  }

  const modalStyle: React.CSSProperties = {
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0 0 10px 0 rgba(0,0,0,.5)',
    height: '85%',
    maxHeight: '90%',
    overflow: 'auto',
    padding: '20px',
    position: 'relative', 
    width: '80%',
  };
  
  
  return (
    <>
      {/* <Sidebar enableMinification={true} userId={userId}/> */}
      {showAssessmentInititialisationModal && (
        <Modal2
          content={
            <AssessmentInitialisationMenu
              onClose={() => setShowAssessmentInitialisationModal(false)}
              preferredProgrammingLanguage={preferredProgrammingLanguage}
              setPreferredProgrammingLanguage={setPreferredProgrammingLanguage}
              setIsTimerRunning={setIsTimerRunning}
              totalAllottedTimeInMinutes={totalAllottedTimeInMinutes}
            />
          }
          closeDisabled={true}
          onClose={doNothing}
        />
      )}
      <div className="courses-container2">
        {showFeedback ? (
          <>
            <AssessmentFeedback latestAssessmentAttemptReport={assessmentAttemptReport} />
          </>
        ) : (
          <div className='boxes-container2'>
            <div className='boxes-wrapper'>
              <div className='content-grid-element'>
                <ChallengeTabbedView
                  unit={currentUnit}
                  challenge={currentChallenge}
                  acceptableAnswers={currentChallenge.acceptable_answers}
                  challengeDescriptionValues={challengeDescriptionValues}
                  solutionByLanguage={currentChallenge.solution_by_language || null}
                  showSolutionView={false}
                  challengeTimeLimit={currentChallenge.time_limit_in_minutes}
                  openSidebar={() => handleToggleSidebar(true)}
                  assessmentTitle={currentAssessment.title}
                  isAssessment={true}
                  currentChallengeRank={currentUnitIndex + 1} // i.e. user attempting challenge `currentUnitIndex` of the total e.g. challenge 2 of 5
                />
              </div>
              <div className='user-response-grid-element' style={{ height: DEFAULT_CONTENT_CONTAINER_MAX_HEIGHT_IN_PX }}>
                {currentChallenge.challenge_type === ChallengeType.CODING ?
                  <CodeEditorBox
                    userId={NOT_SIGNED_IN_USER_USER_ID} // TODO: change this to the actual user id if you wnt to enable code execution in assessments
                    codeEditorValue={""} // TODO: update if coding assessments ever used
                    setCodeEditorValue={(code: string) => { }}// TODO: update if coding assessments ever used
                    codeBlockByLanguage={currentChallenge.code_stub_by_language}
                    timerComplete={handleTimerComplete}
                    unit_id={currentUnit.unit_id}
                    handleUnitAdvance={handleUnitAdvance}
                    codeSubmissionHandler={handleCheckTestCases}
                    codeRunHandler={doNothingAsync}
                    codeEditorSettings={
                      {
                        isRunningDisabled: true,
                        disabled: false,
                        isSubmissionDisabled: false,
                        programmingLanguage: preferredProgrammingLanguage,
                        isAssessment: true,
                        timeLimitInMinutes: currentChallenge.time_limit_in_minutes,
                        isTimerDisabled: false,
                        isTimerRunning: isTimerRunning,
                        setProgrammingLanguage: setPreferredProgrammingLanguage,
                        setIsTimerRunning: setIsTimerRunning,
                        programmingLanguageWhiteList: null,
                        shouldPersistCode: false,
                      }

                    }

                  />
                  :
                  <MultipleChoiceBox
                    isTimerDisabled={false}
                    isTimerRunning={isTimerRunning}
                    setIsTimerRunning={setIsTimerRunning}
                    timeLimitInMinutes={currentChallenge.time_limit_in_minutes}
                    timerComplete={handleTimerComplete}
                    unit_id={currentUnit.unit_id}
                    options={currentChallenge.assessment_options}
                    showMCQOPtions={showMCQOptions}
                    handleShowMCQOptions={(showMCQOptions: boolean) => handleShowMCQOptions(showMCQOptions)}
                    firstChoiceMade={firstChoiceMade}
                    handleMCQSubmission={handleMCQSubmission}
                    isAssessment={true}
                    handleUserDeclaresNotKnowingAnswer={handleUserDeclaresNotKnowingMCQAnswer}
                  />
                }
              </div>
            </div>

            <ChallengeInteractiveMenu 
              challenge={currentChallenge}
              handleSkip={() => handleSkipQuestion(currentChallenge.assessment_type)} 
              handleIDontKnow={undefined} 
              handleRevealSolution={undefined} 
            />

          </div>
        )}
        {showTimedOutModal && (
          <TimedOutModal
            handleUnitAdvance={handleUnitAdvance}
            onClose={() => setShowTimedOutModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default Assessments;