// PrepSessionHighlights.tsx
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from "react-feather";
import './css/PrepSessionHighlights.css';
import { BookSessionButton } from "./BookingAndPayment";
import { CompanyName } from '@/utils/Constants';

// Enum for Prep Session Types
export enum PrepSessionType {
    DEFAULT = 'mock-highlight',
    MOCK_INTERVIEW_SESSION = 'mock-interview',
    COACHING_SESSION = 'coaching-session',
    STRATEGY_SESSION = 'strategy-session',
}

// Interface for Prep Session Highlights
export interface PrepSessionHighlight {
    id: string;
    companyName: CompanyName;
    embedUrl: string;
    title: string;
    altText?: string;
    caption?: string;
}

// PrepSessionHighlightCard Component
interface PrepSessionHighlightCardProps {
    prepSessionHighlight: PrepSessionHighlight;
    prepSessionType: PrepSessionType;
}

const PrepSessionHighlightCard: React.FC<PrepSessionHighlightCardProps> = React.memo(
    ({ prepSessionHighlight, prepSessionType }) => {
        const handleVideoClick = useCallback(() => {
            // Optional: Implement any additional logic if needed when video is clicked
            // Currently, clicking does not perform any action since iframe is embedded
        }, []);

        return (
            <div className={`mock-highlight-card ${prepSessionType}`}>
                <button
                    className="video-thumbnail-container"
                    onClick={handleVideoClick}
                    aria-label={`Play video: ${prepSessionHighlight.title} by ${prepSessionHighlight.companyName}`}
                >
                    {/* Render YouTube embed in iframe */}
                    <iframe
                        loading="lazy"
                        src={prepSessionHighlight.embedUrl}
                        title={prepSessionHighlight.title}
                        className="video-iframe"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        aria-label={prepSessionHighlight.altText || `Video titled ${prepSessionHighlight.title}`}
                    />

                </button>
                <div className="mock-text-container">
                    <p className='mock-brand'>{prepSessionHighlight.companyName} Interviewer</p>
                    <h3 className="mock-title">{prepSessionHighlight.title}</h3>
                    {prepSessionHighlight.caption && (
                        <p className="mock-caption">{prepSessionHighlight.caption}</p>
                    )}
                </div>
            </div>
        );
    }
);

// PrepSessionHighlightsBoard Component
interface PrepSessionHighlightsBoardProps {
    prepSessionHighlights: PrepSessionHighlight[];
    itemsPerPage?: number;
    prepSessionType?: PrepSessionType;
}

const PrepSessionHighlightsBoard: React.FC<PrepSessionHighlightsBoardProps> = React.memo(
    ({ prepSessionHighlights, itemsPerPage = 3, prepSessionType = PrepSessionType.MOCK_INTERVIEW_SESSION }) => {
        const [currentPage, setCurrentPage] = useState<number>(1);
        const totalPages = useMemo(
            () => Math.ceil(prepSessionHighlights.length / itemsPerPage),
            [prepSessionHighlights, itemsPerPage]
        );

        const pageContainers = useMemo(
            () => Array.from({ length: totalPages }, (_, i) =>
                prepSessionHighlights.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
            ),
            [prepSessionHighlights, itemsPerPage, totalPages]
        );

        const carouselSlidesRef = useRef<HTMLDivElement>(null);

        const handlePageChange = useCallback(
            (delta: number) => {
                setCurrentPage(prevPage => {
                    const newPage = prevPage + delta;
                    const wrappedPage = (newPage - 1 + totalPages) % totalPages + 1;
                    return wrappedPage;
                });
            },
            [totalPages]
        );

        const prevPage = useCallback(() => handlePageChange(-1), [handlePageChange]);
        const nextPage = useCallback(() => handlePageChange(1), [handlePageChange]);

        useEffect(() => {
            const translatePercentage = `-${(currentPage - 1) * 100}%`;
            if (carouselSlidesRef.current) {
                carouselSlidesRef.current.style.setProperty('--carousel-translate', translatePercentage);
            }
        }, [currentPage]);

        if (prepSessionHighlights.length === 0) {
            return (
                <div className="carousel-container">
                    <p className="no-highlights-message">No highlights available at the moment.</p>
                </div>
            );
        }

        return (
            <div className="carousel-container">
                <div
                    className="carousel-slides"
                    ref={carouselSlidesRef}
                >
                    {pageContainers.map((pageHighlights, pageIndex) => (
                        <div key={`carousel-page-${pageIndex}`} className="carousel-page">
                            <div className="carousel-grid">
                                {pageHighlights.map((highlight) => (
                                    <PrepSessionHighlightCard
                                        key={highlight.id}
                                        prepSessionHighlight={highlight}
                                        prepSessionType={prepSessionType}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Carousel navigation controls */}
                <div className="carousel-controls">
                    <button onClick={prevPage} className="carousel-button prev" aria-label="Previous Page">
                        <ChevronLeft size={40} />
                    </button>
                    <button onClick={nextPage} className="carousel-button next" aria-label="Next Page">
                        <ChevronRight size={40} />
                    </button>
                </div>

                {/* Carousel indicators */}
                <div className="carousel-indicators">
                    {Array.from({ length: totalPages }).map((_, i) => (
                        <button
                            key={`indicator-dot-${i}`}
                            className={`indicator-dot ${currentPage === i + 1 ? 'active' : ''}`}
                            onClick={() => setCurrentPage(i + 1)}
                            aria-label={`Go to page ${i + 1}`}
                        ></button>
                    ))}
                </div>
            </div>
        );
    }
);

// Main PrepSessionHighlights Component
const PrepSessionHighlights: React.FC = () => {
    const sampleHighlights: PrepSessionHighlight[] = [
        // {
        //     id: '1',
        //     companyName: CompanyName.AMAZON,
        //     title: "System Design",
        //     embedUrl: "https://www.youtube.com/embed/Lq8BbgDM0uw",
        //     altText: "System Design Interview by Amazon",
        //     caption: "This video explores the system design for a multi-round contest with audience voting, ideal for building foundational skills.",
        // },
        // {
        //     id: '2',
        //     companyName: CompanyName.GOOGLE,
        //     title: "Algorithms Deep Dive",
        //     embedUrl: "https://www.youtube.com/embed/Lq8BbgDM0uw",
        //     altText: "Algorithms Deep Dive by Google",
        //     caption: "An in-depth look into algorithms essential for technical interviews at Google.",
        // },
        // {
        //     id: '3',
        //     companyName: CompanyName.META,
        //     title: "Behavioral Interview Tips",
        //     embedUrl: "https://www.youtube.com/embed/Lq8BbgDM0uw",
        //     altText: "Behavioral Interview Tips by Meta",
        //     caption: "Strategies to excel in behavioral interviews at Meta.",
        // },
        // {
        //     id: '4',
        //     companyName: CompanyName.AMAZON,
        //     title: "Insiders Tips",
        //     embedUrl: "https://www.youtube.com/embed/GDNmHiYK3Gk",
        //     altText: "Insiders Tips by Amazon",
        //     caption: "Boost your Amazon interview prep with these essential 2024 techniques to sharpen your skills.",
        // },
        // {
        //     id: '5',
        //     companyName: CompanyName.GOOGLE,
        //     title: "Advanced Coding Problems",
        //     embedUrl: "https://www.youtube.com/embed/GDNmHiYK3Gk",
        //     altText: "Advanced Coding Problems by Google",
        //     caption: "Tackle advanced coding challenges to prepare for Google interviews.",
        // },
        // {
        //     id: '6',
        //     companyName: CompanyName.META,
        //     title: "Leadership Principles",
        //     embedUrl: "https://www.youtube.com/embed/GDNmHiYK3Gk",
        //     altText: "Leadership Principles by Meta",
        //     caption: "Understand the leadership principles that drive success at Meta.",
        // },
    ];

    return (
        <div className="mock-highlights-container">
            <div className="mock-highlights-text-container">
                <h1>Past mock and coaching session highlights</h1>
                <p>
                    To get a feel for what our mock and coaching sessions are like, check out some of the highlights below!
                </p>
            </div>

            <div className='book-session-button-container'>
                <BookSessionButton />
            </div>

            <div className="mock-board-container">
                <PrepSessionHighlightsBoard 
                    prepSessionHighlights={sampleHighlights}
                    itemsPerPage={6}
                    prepSessionType={PrepSessionType.MOCK_INTERVIEW_SESSION}
                />
            </div>

            <div className='book-session-button-container'>
                <BookSessionButton />
            </div>
        </div>
    );
}

export default PrepSessionHighlights;
