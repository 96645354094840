import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Tooltip } from "react-tooltip";
import "../css/Navbar.css";
import {
  BLOG_PAGE_PATH,
  START_MENU_PAGE_PATH,
  LEARNING_PAGE_PATH,
  SIGN_IN_PAGE_PATH,
  SIGN_UP_PAGE_PATH,
  CODITIONING_LOGO_URL,
  DIAGNOSTICS_PAGE_PATH,
  MOCK_INTERVIEW_PAGE_PATH,
  SUBSCRIPTIONS_PAGE_PATH,
  CV_SERVICES_PAGE_PATH,
} from "../utils/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import Modal from "../utils/Modal";
import FeedbackPage from "./FeedbackPage";
import { useNavigate } from "react-router-dom";
import { DiscountBannerButton } from "@/utils/BannerButton";

interface NavBarProps {
  signedIn: boolean;
  handleSignOut: () => void;
  handleSettingsClick: () => void;
  handleProfileClick: () => void;
}

interface NavItemProps {
  urlPath: string;
  labelText: string;
  className: string;
  beta?: boolean;
}

const NavBarItem: React.FC<NavItemProps> = ({ urlPath, labelText, className, beta = false }) => (
  <li className={className}>
    <Link to={urlPath}>{labelText}</Link>{beta && <span className="beta-label">BETA</span>}
  </li>
);

const DiagnosticsNavBarItem: React.FC = () => {
  return NavBarItem({
    urlPath: DIAGNOSTICS_PAGE_PATH,
    labelText: "🩺Diagnostics",
    className: "button",
  });
}

const MockInterviewNavBarItem: React.FC = () => {
  return NavBarItem({
    urlPath: MOCK_INTERVIEW_PAGE_PATH,
    labelText: "Mock Interview",
    className: "button",
  });
}

const LogoNavBarItem: React.FC = () => {
  return (
    <li className="site-logo-container">
      <Link to="/">
        <img
          src={CODITIONING_LOGO_URL}
          alt="Logo"
        />
      </Link>
    </li>
  );
}

const LearningNavBarItem: React.FC = () => {
  return NavBarItem({
    urlPath: LEARNING_PAGE_PATH,
    labelText: "Learn",
    className: "button",
    beta: true,
  });
}

const CV_OPTIMISATION_MENU_ITEM_LABEL_TEXT = "CV Optimization";
const CVOptimisationServiceNavBarItem: React.FC = () => {
  return NavBarItem({
    urlPath: CV_SERVICES_PAGE_PATH,
    labelText: CV_OPTIMISATION_MENU_ITEM_LABEL_TEXT,
    className: "button",
  });
}

const SignUpNavBarItem: React.FC = () => {
  return (
    <>
      <AutoAwesomeIcon style={{ marginRight: "5px" }} />
      <Link to={SIGN_UP_PAGE_PATH}>Sign Up</Link>
    </>
  );
}

const SubscriptionNavBarItem: React.FC = () => {
  return (
    <>
      <AccountCircleIcon style={{ marginRight: "5px" }} />
      <Link to={SUBSCRIPTIONS_PAGE_PATH}>Subscription</Link>
    </>
  );
}

const NavBar: React.FC<NavBarProps> = ({
  signedIn,
  handleSignOut,
  handleSettingsClick,
  handleProfileClick,
}) => {
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const toggleFeedbackModal = () => {
    setIsFeedbackModalVisible(!isFeedbackModalVisible);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const navigate = useNavigate();
  const handleStartJourney = () => {
    navigate(START_MENU_PAGE_PATH);
  };

  return (
    <>
      <nav className={`navbar ${isHomePage ? "home" : ""}`}>
        <ul>
          <LogoNavBarItem />
          <DiagnosticsNavBarItem />
          <MockInterviewNavBarItem />
          <LearningNavBarItem />
          <CVOptimisationServiceNavBarItem />

          <li className="button">
            <Link to={BLOG_PAGE_PATH}>Blog</Link>
          </li>
          <li className="button">
            <a onClick={toggleFeedbackModal} style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faCommentDots} /> Feedback
            </a>
          </li>

          {!signedIn && (
            <li className="button">
              <SignUpNavBarItem />
            </li>
          )}
          {signedIn && (
            <li className="button">
              <SubscriptionNavBarItem />
            </li>
          )}
          {!signedIn && (
            <li className="button logIn">
              <Link to={SIGN_IN_PAGE_PATH}>Log In</Link>
            </li>
          )}
          {signedIn && (
            <li className="button">
              <Link to={BLOG_PAGE_PATH} onClick={handleSignOut}>
                Sign Out
              </Link>
            </li>
          )}
        </ul>
        {isHomePage && !isFeedbackModalVisible && (
          <div
            className="actionBtn animatedBorder"
            onClick={handleStartJourney}
          >
            Start Journey
          </div>
        )}
        <div className="icons">
          <SettingsIcon
            className="icon disabled-icon"
            onClick={handleSettingsClick}
            data-tooltip-id="settings-tooltip"
            data-tooltip-content="Settings"
          />
          <AccountCircleIcon
            className="icon disabled-icon"
            onClick={handleProfileClick}
            data-tooltip-id="profile-tooltip"
            data-tooltip-content="View your profile"
          />
        </div>
        <Tooltip id="profile-tooltip" />
        <Tooltip id="settings-tooltip" />
      </nav>
      <DiscountBannerButton/>

      <nav className={`mobileNav ${isHomePage ? "home" : ""}`}>
        <ul>
          <LogoNavBarItem />
          <DiagnosticsNavBarItem />
          {/* <MockInterviewNavBarItem /> */}

          <div className="hamburger-menu">
            <div
              className={`hamburger-icon ${isOpen ? "open" : ""}`}
              onClick={toggleMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              className={`hamburger-links ${isOpen ? "open" : ""}`}
              onClick={toggleMenu}
            >
              <li className="button" onClick={toggleMenu}>
                <Link to={CV_SERVICES_PAGE_PATH}>{CV_OPTIMISATION_MENU_ITEM_LABEL_TEXT}</Link>
              </li>

              <li className="button" onClick={toggleMenu}>
                <Link to={MOCK_INTERVIEW_PAGE_PATH}>Mock Interviews</Link>
              </li>
              <li className="button" onClick={toggleMenu}>
                <Link to={LEARNING_PAGE_PATH}>Tailored Learning</Link>
              </li>
              <li className="button" onClick={toggleMenu}>
                <Link to={BLOG_PAGE_PATH}>Blog</Link>
              </li>
              <li className="button" onClick={toggleMenu}>
                <a onClick={toggleFeedbackModal} style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon icon={faCommentDots} /> Feedback
                </a>
              </li>

              <div className="credentials">
                {!signedIn && (
                  <li className="button signUp">
                    <SignUpNavBarItem />
                  </li>
                )}
                {signedIn && (
                  <li className="button">
                    <SubscriptionNavBarItem />
                  </li>
                )}
                {!signedIn && (
                  <li className="button logIn">
                    <Link to={SIGN_IN_PAGE_PATH}>Log In</Link>
                  </li>
                )}
                {signedIn && (
                  <li className="button signOut">
                    <Link to={BLOG_PAGE_PATH} onClick={handleSignOut}>
                      SIGN OUT
                    </Link>
                  </li>
                )}
              </div>
            </div>
          </div>
        </ul>
      </nav>

      {isFeedbackModalVisible && (
        <Modal content={<FeedbackPage />} onClose={toggleFeedbackModal} backgroundColor="#f5f5f5"></Modal>
      )}
    </>
  );
};

export default NavBar;